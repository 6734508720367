import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFragmentOnContactFeedFragmentDoc } from '../../../crm/contacts/__generated__/contact-fragment.generated';
import { StepImageUploadOnFeedFragmentDoc } from '../../../media/step-image-uploads/__generated__/step-image-upload.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateContentNarrationMutationVariables = Types.Exact<{
  input: Types.UpdateContentNarrationInput;
}>;


export type UpdateContentNarrationMutation = { __typename?: 'RootMutationType', updateContentNarration?: { __typename?: 'ContentNarrationResult', content?: { __typename?: 'Content', id: string, type: Types.ContentType, groupType: Types.ContentGroupType, body?: any | null, label?: string | null, plainDescription?: string | null, embedLink?: string | null, providerName?: string | null, videoContain?: boolean | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null, image?: { __typename?: 'StepImageUpload', id: string, name?: string | null, originalUrl?: string | null, fhdUrl?: string | null, alternativeText?: string | null, size?: number | null, insertedAt?: any | null, width?: number | null, height?: number | null, originalWidth?: number | null, originalHeight?: number | null } | null, narration?: { __typename?: 'ContentNarration', id: string, title?: string | null, type?: Types.NarrationType | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null } | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateContentNarrationDocument = gql`
    mutation updateContentNarration($input: UpdateContentNarrationInput!) {
  updateContentNarration(input: $input) {
    content {
      id
      type
      groupType
      body
      label
      plainDescription
      embedLink
      providerName
      videoContain
      video {
        id
        rawUrl
        alternativeText
        transcodedUrl
        previewUrl
        streamUrl
        thumbUrl
        gifUrl
        gifWithIconUrl
        contact {
          ...ContactFragmentOnContactFeed
        }
      }
      image {
        ...StepImageUploadOnFeed
      }
      narration {
        id
        title
        type
        video {
          id
          rawUrl
          transcodedUrl
          previewUrl
          streamUrl
          gifUrl
          gifWithIconUrl
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}
${StepImageUploadOnFeedFragmentDoc}`;
export type UpdateContentNarrationMutationFn = Apollo.MutationFunction<UpdateContentNarrationMutation, UpdateContentNarrationMutationVariables>;

/**
 * __useUpdateContentNarrationMutation__
 *
 * To run a mutation, you first call `useUpdateContentNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentNarrationMutation, { data, loading, error }] = useUpdateContentNarrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContentNarrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentNarrationMutation, UpdateContentNarrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentNarrationMutation, UpdateContentNarrationMutationVariables>(UpdateContentNarrationDocument, options);
      }
export type UpdateContentNarrationMutationHookResult = ReturnType<typeof useUpdateContentNarrationMutation>;
export type UpdateContentNarrationMutationResult = Apollo.MutationResult<UpdateContentNarrationMutation>;
export type UpdateContentNarrationMutationOptions = Apollo.BaseMutationOptions<UpdateContentNarrationMutation, UpdateContentNarrationMutationVariables>;