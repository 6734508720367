import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { StepImageUploadOnFeedFragmentDoc } from './step-image-upload.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStepImageUploadMutationVariables = Types.Exact<{
  input: Types.UpdateStepImageUploadInput;
}>;


export type UpdateStepImageUploadMutation = { __typename?: 'RootMutationType', updateStepImageUpload?: { __typename?: 'StepImageUploadResult', stepImageUpload?: { __typename?: 'StepImageUpload', id: string, name?: string | null, originalUrl?: string | null, fhdUrl?: string | null, alternativeText?: string | null, size?: number | null, insertedAt?: any | null, width?: number | null, height?: number | null, originalWidth?: number | null, originalHeight?: number | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateStepImageUploadDocument = gql`
    mutation updateStepImageUpload($input: UpdateStepImageUploadInput!) {
  updateStepImageUpload(input: $input) {
    stepImageUpload {
      ...StepImageUploadOnFeed
    }
    userErrors {
      field
      message
    }
  }
}
    ${StepImageUploadOnFeedFragmentDoc}`;
export type UpdateStepImageUploadMutationFn = Apollo.MutationFunction<UpdateStepImageUploadMutation, UpdateStepImageUploadMutationVariables>;

/**
 * __useUpdateStepImageUploadMutation__
 *
 * To run a mutation, you first call `useUpdateStepImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStepImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStepImageUploadMutation, { data, loading, error }] = useUpdateStepImageUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStepImageUploadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStepImageUploadMutation, UpdateStepImageUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStepImageUploadMutation, UpdateStepImageUploadMutationVariables>(UpdateStepImageUploadDocument, options);
      }
export type UpdateStepImageUploadMutationHookResult = ReturnType<typeof useUpdateStepImageUploadMutation>;
export type UpdateStepImageUploadMutationResult = Apollo.MutationResult<UpdateStepImageUploadMutation>;
export type UpdateStepImageUploadMutationOptions = Apollo.BaseMutationOptions<UpdateStepImageUploadMutation, UpdateStepImageUploadMutationVariables>;