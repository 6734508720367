import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type StepImageUploadEdgeOnStepImageUploadFeedFragment = { __typename?: 'StepImageUploadEdge', node?: { __typename?: 'StepImageUpload', id: string, name?: string | null, originalUrl?: string | null, fhdUrl?: string | null, alternativeText?: string | null, size?: number | null, insertedAt?: any | null, width?: number | null, height?: number | null, originalWidth?: number | null, originalHeight?: number | null } | null };

export type StepImageUploadOnFeedFragment = { __typename?: 'StepImageUpload', id: string, name?: string | null, originalUrl?: string | null, fhdUrl?: string | null, alternativeText?: string | null, size?: number | null, insertedAt?: any | null, width?: number | null, height?: number | null, originalWidth?: number | null, originalHeight?: number | null };

export const StepImageUploadOnFeedFragmentDoc = gql`
    fragment StepImageUploadOnFeed on StepImageUpload {
  id
  name
  originalUrl
  fhdUrl
  alternativeText
  size
  insertedAt
  width
  height
  originalWidth
  originalHeight
}
    `;
export const StepImageUploadEdgeOnStepImageUploadFeedFragmentDoc = gql`
    fragment StepImageUploadEdgeOnStepImageUploadFeed on StepImageUploadEdge {
  node {
    ...StepImageUploadOnFeed
  }
}
    ${StepImageUploadOnFeedFragmentDoc}`;