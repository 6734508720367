import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { StepImageUploadOnFeedFragmentDoc } from '../../step-image-uploads/__generated__/step-image-upload.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNarrationVideoUploadMutationVariables = Types.Exact<{
  input: Types.CreateNarrationVideoUploadInput;
  narrationType: Types.NarrationType;
}>;


export type CreateNarrationVideoUploadMutation = { __typename?: 'RootMutationType', createNarrationVideoUpload?: { __typename?: 'VideoUploadResult', videoUpload?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null } | null, content?: { __typename?: 'Content', id: string, type: Types.ContentType, groupType: Types.ContentGroupType, body?: any | null, label?: string | null, plainDescription?: string | null, embedLink?: string | null, providerName?: string | null, videoContain?: boolean | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null } | null, image?: { __typename?: 'StepImageUpload', id: string, name?: string | null, originalUrl?: string | null, fhdUrl?: string | null, alternativeText?: string | null, size?: number | null, insertedAt?: any | null, width?: number | null, height?: number | null, originalWidth?: number | null, originalHeight?: number | null } | null, narration?: { __typename?: 'ContentNarration', id: string, title?: string | null, type?: Types.NarrationType | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null } | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateNarrationVideoUploadDocument = gql`
    mutation createNarrationVideoUpload($input: CreateNarrationVideoUploadInput!, $narrationType: NarrationType!) {
  createNarrationVideoUpload(input: $input, narrationType: $narrationType) {
    videoUpload {
      id
      rawUrl
      transcodedUrl
      previewUrl
      streamUrl
      gifUrl
      gifWithIconUrl
    }
    content {
      id
      type
      groupType
      body
      label
      plainDescription
      embedLink
      providerName
      videoContain
      video {
        id
        rawUrl
        transcodedUrl
        previewUrl
        streamUrl
        thumbUrl
        gifUrl
        gifWithIconUrl
      }
      image {
        ...StepImageUploadOnFeed
      }
      narration {
        id
        title
        type
        video {
          id
          rawUrl
          transcodedUrl
          previewUrl
          streamUrl
          thumbUrl
          gifUrl
          gifWithIconUrl
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}
    ${StepImageUploadOnFeedFragmentDoc}`;
export type CreateNarrationVideoUploadMutationFn = Apollo.MutationFunction<CreateNarrationVideoUploadMutation, CreateNarrationVideoUploadMutationVariables>;

/**
 * __useCreateNarrationVideoUploadMutation__
 *
 * To run a mutation, you first call `useCreateNarrationVideoUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNarrationVideoUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNarrationVideoUploadMutation, { data, loading, error }] = useCreateNarrationVideoUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *      narrationType: // value for 'narrationType'
 *   },
 * });
 */
export function useCreateNarrationVideoUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateNarrationVideoUploadMutation, CreateNarrationVideoUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNarrationVideoUploadMutation, CreateNarrationVideoUploadMutationVariables>(CreateNarrationVideoUploadDocument, options);
      }
export type CreateNarrationVideoUploadMutationHookResult = ReturnType<typeof useCreateNarrationVideoUploadMutation>;
export type CreateNarrationVideoUploadMutationResult = Apollo.MutationResult<CreateNarrationVideoUploadMutation>;
export type CreateNarrationVideoUploadMutationOptions = Apollo.BaseMutationOptions<CreateNarrationVideoUploadMutation, CreateNarrationVideoUploadMutationVariables>;