/* eslint-disable @typescript-eslint/no-var-requires */
import { ApolloClient, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import fetch from 'cross-fetch';
import { Cookies } from 'react-cookie';

import introspectionResult from '../graphql/__generated__/introspection-result';
import { authTokenStore } from '../zustand/auth-token-store';
import {
  fetchAccessToken,
  getAccessToken,
  handleFetch,
  handleResponse,
  isTokenValidOrUndefined,
} from './auth-utils';
import { storyPlayerState } from './cache/story-player';

const createLink = (require('apollo-absinthe-upload-link') as any).createLink;

const cookies = new Cookies();

const handleError = (err: any) => {
  // full control over handling token fetch Error
  console.warn('Your refresh token is invalid. Try to relogin');
  console.error(err);
  // your custom action here
  const { setState } = authTokenStore;
  // router.push("/login");
  setState({ accessToken: null, refreshToken: null });
  apolloClient.resetStore();
};

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'tokens', // accessToken, refreshToken
  isTokenValidOrUndefined,
  fetchAccessToken,
  handleFetch,
  handleResponse,
  handleError,
});

const cache = new InMemoryCache({
  addTypename: true,
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    storyUnreadCount: {
      keyFields: ['storyId'],
    },
    channelUnreadCount: {
      keyFields: ['channelId'],
    },
    MultipleChoiceInteraction: {
      keyFields: ['multipleChoiceInteractionId'],
    },
    ShoppableInteraction: {
      keyFields: ['shoppableInteractionId'],
    },
    HotspotInteraction: {
      keyFields: ['hotspotInteractionId'],
    },
    Query: {
      fields: {
        channels: relayStylePagination(),
        initiations: relayStylePagination(),
        myInitiations: relayStylePagination(),
        members: relayStylePagination(),
        memberInvitations: relayStylePagination(),
        videoUploads: relayStylePagination(),
        fileUploads: relayStylePagination(),
        storyPlayerState: {
          read() {
            return storyPlayerState();
          },
        },
        storyLinks: relayStylePagination(),
        storyTimelines: relayStylePagination(),
      },
    },
  },
});

const API_ENDPOINT = '/graphql';

const uploadLink = createLink({
  uri: API_ENDPOINT,
  credentials: 'include',
  fetch,
});

const authLink = setContext(async (_, { headers }) => {
  // for prototyping, use local storage.
  const token = getAccessToken();
  const storySessionToken =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('story-session-token')
      : null;
  const locale = cookies.get('NEXT_LOCALE') || 'en';
  return {
    headers: {
      ...headers,
      'Accept-Language': `${locale};q=0.5`,
      authorization: token ? `Bearer ${token}` : '',
      'x-sharelo-story-session-token': storySessionToken
        ? storySessionToken
        : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([refreshLink, authLink, uploadLink]),
  cache,
});
