import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { StepImageUploadOnFeedFragmentDoc } from './step-image-upload.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StepImageUploadsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.StepImageUploadFilter>;
}>;


export type StepImageUploadsQuery = { __typename?: 'RootQueryType', stepImageUploads?: { __typename?: 'StepImageUploadConnection', totalCount: number, edges?: Array<{ __typename?: 'StepImageUploadEdge', node?: { __typename?: 'StepImageUpload', id: string, name?: string | null, originalUrl?: string | null, fhdUrl?: string | null, alternativeText?: string | null, size?: number | null, insertedAt?: any | null, width?: number | null, height?: number | null, originalWidth?: number | null, originalHeight?: number | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type StepImageUploadQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type StepImageUploadQuery = { __typename?: 'RootQueryType', stepImageUpload?: { __typename?: 'StepImageUpload', id: string, name?: string | null, originalUrl?: string | null, fhdUrl?: string | null, alternativeText?: string | null, size?: number | null, insertedAt?: any | null, width?: number | null, height?: number | null, originalWidth?: number | null, originalHeight?: number | null } | null };


export const StepImageUploadsDocument = gql`
    query stepImageUploads($after: String, $before: String, $first: Int, $filter: StepImageUploadFilter) {
  stepImageUploads(after: $after, before: $before, first: $first, filter: $filter) {
    edges {
      node {
        ...StepImageUploadOnFeed
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${StepImageUploadOnFeedFragmentDoc}`;

/**
 * __useStepImageUploadsQuery__
 *
 * To run a query within a React component, call `useStepImageUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepImageUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepImageUploadsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStepImageUploadsQuery(baseOptions?: Apollo.QueryHookOptions<StepImageUploadsQuery, StepImageUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepImageUploadsQuery, StepImageUploadsQueryVariables>(StepImageUploadsDocument, options);
      }
export function useStepImageUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepImageUploadsQuery, StepImageUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepImageUploadsQuery, StepImageUploadsQueryVariables>(StepImageUploadsDocument, options);
        }
export type StepImageUploadsQueryHookResult = ReturnType<typeof useStepImageUploadsQuery>;
export type StepImageUploadsLazyQueryHookResult = ReturnType<typeof useStepImageUploadsLazyQuery>;
export type StepImageUploadsQueryResult = Apollo.QueryResult<StepImageUploadsQuery, StepImageUploadsQueryVariables>;
export function refetchStepImageUploadsQuery(variables?: StepImageUploadsQueryVariables) {
      return { query: StepImageUploadsDocument, variables: variables }
    }
export const StepImageUploadDocument = gql`
    query stepImageUpload($id: ID!) {
  stepImageUpload(id: $id) {
    ...StepImageUploadOnFeed
  }
}
    ${StepImageUploadOnFeedFragmentDoc}`;

/**
 * __useStepImageUploadQuery__
 *
 * To run a query within a React component, call `useStepImageUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepImageUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepImageUploadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStepImageUploadQuery(baseOptions: Apollo.QueryHookOptions<StepImageUploadQuery, StepImageUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepImageUploadQuery, StepImageUploadQueryVariables>(StepImageUploadDocument, options);
      }
export function useStepImageUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepImageUploadQuery, StepImageUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepImageUploadQuery, StepImageUploadQueryVariables>(StepImageUploadDocument, options);
        }
export type StepImageUploadQueryHookResult = ReturnType<typeof useStepImageUploadQuery>;
export type StepImageUploadLazyQueryHookResult = ReturnType<typeof useStepImageUploadLazyQuery>;
export type StepImageUploadQueryResult = Apollo.QueryResult<StepImageUploadQuery, StepImageUploadQueryVariables>;
export function refetchStepImageUploadQuery(variables: StepImageUploadQueryVariables) {
      return { query: StepImageUploadDocument, variables: variables }
    }